import { Notice, QueuePosition } from "bouncer-waitingroom";

export class WaitingRoomHandler {
    // postion info
    private positionInfoElement: HTMLParagraphElement;

    // notices
    private noticeListElement: HTMLUListElement;

    // enter button
    private enterButtonElement: HTMLButtonElement;


    constructor() {
        this.positionInfoElement = document.querySelector("#position-info") as HTMLParagraphElement;

        this.noticeListElement = document.querySelector("#notice-list") as HTMLUListElement;

        this.enterButtonElement = document.querySelector("#enter") as HTMLButtonElement;

        this.registerHandlers();
    }


    private registerHandlers(): void {
        document.addEventListener("bouncer:queuePositionUpdated", (e) => this.updateQueuePosition(e.detail));
        document.addEventListener("bouncer:noticesUpdated", (e) => this.updateNotices(e.detail));
    }

    private updateQueuePosition(queuePosition: QueuePosition | null): void {
        this.updateProgressText(queuePosition);
    }

    private updateProgressText(queuePosition: QueuePosition | null): void {
        if (queuePosition === null) {
            console.info("prequeue positionInfoElement text applied")

            this.positionInfoElement.innerText = "Du bist im Vorwarteraum. Deine Position wird dir gleich angezeigt!"
            this.enterButtonElement.disabled = true;

            return;
        }

        if (queuePosition.position === 1) {
            console.info("one ticket ahead positionInfoElement text applied")

            this.positionInfoElement.innerText = "Ein Fan ist vor Dir!"
            this.enterButtonElement.disabled = true;

            return;
        }

        if (queuePosition.position <= 0) {
            console.info("its this tickets turn positionInfoElement text applied")

            this.positionInfoElement.innerText = "Du bist dran!"
            this.enterButtonElement.disabled = false;

            return;
        }

        this.enterButtonElement.disabled = true;
        this.positionInfoElement.innerText = `${queuePosition.position} Fans sind vor Dir!`;
    }

    private updateNotices(notices: Array<Notice>): void {
        let updatedNoticeList = "";
        notices.forEach(n => updatedNoticeList += `<li>${n.message}</li>`);

        this.noticeListElement.innerHTML = updatedNoticeList;
    }
}
